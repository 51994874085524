export const headerLinkColor = '#4F4D56';
export const headerTextColor = '#979797';

export const bookFontFamily = "'wavehaus_book', Helvetica, 'sans-serif'";
export const boldFontFamily = "'wavehaus_bold', Helvetica, 'sans-serif'";

export const semiBoldFontFamily = "'gilroy_semibold', Helvetica, 'sans-serif'";
export const lightFontFamily = "'gilroy_light', Helvetica, 'sans-serif'";

export const fullWidth = '1200px';
export const contentFullWidth = '954px';
export const contentHorizontalPadding = '18px';

export const mobileSize = '768px';
