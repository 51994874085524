import PropTypes from 'prop-types';
import React from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';

import { NavLink } from './HeaderNavDesktop';

const Nav = styled.nav`
  overflow: hidden;
  width: 100%;

  @media (min-width: 769px) {
    display: none;
  }
`;

const MobileNavLink = styled(NavLink)`
  line-height: 22px;
  padding: 12px 0;
  text-align: center;
  width: 100%;
`;

const HeaderNavMobile = ({ navLinks, isOpen }) => {
  const animatedProps = useSpring({ height: isOpen ? 50 * navLinks.length : 0 });

  return (
    <Nav>
      <animated.div style={animatedProps}>
        {navLinks.map((navLink) => (
          <MobileNavLink
            key={navLink.link}
            to={navLink.link}
            activeClassName="active"
          >
            <span>{navLink.text}</span>
          </MobileNavLink>
        ))}
      </animated.div>
    </Nav>
  );
};

HeaderNavMobile.propTypes = {
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default HeaderNavMobile;
