import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {
  bookFontFamily,
  headerLinkColor,
  mobileSize,
} from '../base_styles';

const Nav = styled.nav`
  display: flex;

  @media (max-width: ${mobileSize}) {
    display: none;
  }
`;

export const NavLink = styled(Link)`
  align-items: center;
  color: ${headerLinkColor};
  cursor: pointer;
  display: flex;
  font-family: ${bookFontFamily};
  font-size: 18px;
  justify-content: center;
  letter-spacing: 0.4px;
  line-height: 22px;
  outline: none;
  text-decoration: none;
  width: 130px;

  &:hover span:after {
    content: "";
    display: block;
    outline: 2px solid #C5F9E9;
    position: relative;
  }

  &.active span:after {
    content: "";
    display: block;
    outline: 2px solid #C5F9E9;
    position: relative;
  }
`;

const HeaderNavDesktop = ({ navLinks }) => (
  <Nav>
    {navLinks.map((navLink) => (
      <NavLink
        key={navLink.link}
        to={navLink.link}
        activeClassName="active"
      >
        <span>{navLink.text}</span>
      </NavLink>
    ))}
  </Nav>
);

HeaderNavDesktop.propTypes = {
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default HeaderNavDesktop;
