import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  display: flex;
  padding-top:3px;

  @media (min-width: 769px) {
    display: none;
  }
`;

const ButtonHamburger = styled.span`
  border: 2px solid #454545;
  border-radius: 10px;
  display: block;
  margin-bottom: 6px;
  opacity: 1;
  transition: transform 150ms ease-in-out, opacity 110ms;
  width: 32px;
`;

const Button = styled.button`
  appearance: none;
  background: transparent;
  border:none;
  cursor: pointer;
  outline: none;
`;

const ButtonHamburgerInnerTop = styled(ButtonHamburger)`
  position: absolute;
  transform: ${(props) => (props.isOpen ? 'rotate(45deg)' : '')};
`;

const ButtonHamburgerInnerBottom = styled(ButtonHamburger)`
  transform: ${(props) => (props.isOpen ? 'rotate(-45deg)' : '')};
`;

const ButtonHamburgerOuter = styled(ButtonHamburger)`
  ${(props) => (props.isOpen ? 'opacity: 0;' : '')}
`;

const HeaderNavMobileButton = ({ isOpen, onOpen }) => (
  <ButtonWrapper>
    <Button
      onClick={() => onOpen(!isOpen)}
      type="button"
    >
      <ButtonHamburgerOuter isOpen={isOpen} />
      <ButtonHamburgerInnerTop isOpen={isOpen} />
      <ButtonHamburgerInnerBottom isOpen={isOpen} />
      <ButtonHamburgerOuter isOpen={isOpen} />
    </Button>
  </ButtonWrapper>
);

HeaderNavMobileButton.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
};

export default HeaderNavMobileButton;
