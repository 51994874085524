import React from 'react';
import styled from 'styled-components';

import {
  fullWidth,
  lightFontFamily,
  semiBoldFontFamily,
  mobileSize,
} from '../base_styles';
import Logo from '../icons/Logo';

const FooterWrapper = styled.div`
  background: #4F4D56;
  clear: both;
`;

const FooterInner = styled.footer`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: ${fullWidth};
  padding: 24px 18px;
`;

const FooterTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FooterMailto = styled.a`
  color: white;
  font-family: ${semiBoldFontFamily};
  font-size: 16px;
  line-height: 28px;
  text-decoration: none;
`;

const FooterText = styled.p`
  color: white;
  font-family: ${lightFontFamily};
`;

const FooterLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${mobileSize}) {
    margin-top: 20px;
  }
`;

const FooterLogo = styled(Logo)`
  height: 32px;
`;

const Footer = () => (
  <FooterWrapper>
    <FooterInner>
      <FooterTextWrapper>
        <FooterMailto href="mailto:hello@beckyalford.co.uk">hello@beckyalford.co.uk</FooterMailto>
        <FooterText>© Becky Alford</FooterText>
      </FooterTextWrapper>
      <FooterLogoWrapper>
        <FooterLogo fill="#C9F9E6" />
      </FooterLogoWrapper>
    </FooterInner>
  </FooterWrapper>
);

export default Footer;
