import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';

import {
  headerTextColor,
  fullWidth,
  semiBoldFontFamily,
  mobileSize,
} from '../base_styles';
import HeaderNavDesktop from './HeaderNavDesktop';
import HeaderNavMobile from './HeaderNavMobile';
import HeaderNavMobileButton from './HeaderNavMobileButton';
import Logo from '../icons/Logo';

const HeaderOuter = styled.header`
  background: white;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.12);
  color: ${headerTextColor};
  position: fixed;
  width: 100%;
  z-index: 10;
`;

const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: ${fullWidth};
  padding: 0 18px;
`;

const HeaderLogoLink = styled(Link)`
  align-items: center;
  color: ${headerTextColor};
  display: flex;
  font-family: ${semiBoldFontFamily};
  font-size: 18px;
  justify-content: center;
  letter-spacing: 0.5px;
  line-height: 16px;
  text-decoration: none;
`;

const HeaderLogoText = styled.span`
  font-size: 16px;
  opacity: ${(props) => (props.isScrolled ? '0' : '100')};
  text-transform: uppercase;
  transition: opacity 0.2s ease;

  @media (max-width: ${mobileSize}) {
    display: none;
  }
`;

const HeaderLogo = styled(Logo)`
  margin: ${(props) => (props.isScrolled ? '16px 24px 16px 0' : '26px 24px 26px 0')};
  transition: margin 0.2s ease;

  svg {
    height: ${(props) => (props.isScrolled ? '24px' : '36px')};
    transition: height 0.2s ease;
  }

  @media (max-width: ${mobileSize}) {
    svg {
      height: 24px;
    }
  }
`;

const HeaderSpacer = styled.div`
  height: 58px;

  @media (max-width: ${mobileSize}) {
    height: 60px;
  }
`;

const navLinks = [
  {
    link: '/about/',
    text: 'About',
  },
  {
    link: '/work/',
    text: 'Work',
  },
  {
    link: '/contact/',
    text: 'Contact',
  },
];

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // detect scrolling
  useScrollPosition(({ currPos }) => {
    if (currPos.y < -18 && !isScrolled) {
      setIsScrolled(true);
    } else if (currPos.y > -18) {
      setIsScrolled(false);
    }
  }, [isScrolled], null, false, 300);

  return (
    <>
      <HeaderOuter>
        <HeaderInner>
          <HeaderLogoLink to="/">
            <HeaderLogo isScrolled={isScrolled} />
            <HeaderLogoText isScrolled={isScrolled}>
              Becky Alford Designs
            </HeaderLogoText>
          </HeaderLogoLink>
          <HeaderNavDesktop navLinks={navLinks} isScrolled={isScrolled} />
          <HeaderNavMobileButton isOpen={isOpen} onOpen={setIsOpen} isScrolled={isScrolled} />
        </HeaderInner>
        <HeaderNavMobile navLinks={navLinks} isOpen={isOpen} />
      </HeaderOuter>
      <HeaderSpacer />
    </>
  );
};

export default Header;
