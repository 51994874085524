import PropTypes from 'prop-types';
import React from 'react';

const Logo = ({ className, fill = '#5FDECF' }) => (
  <span className={className}>
    <svg width="62" height="36" viewBox="0 0 62 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M26.2422 35.7586H62.0008L44.1212 0L26.2422 35.7586Z" fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.0081 17.8886C24.5933 16.1316 26.2931 13.1489 26.2931 9.77998C26.2931 4.38819 21.9399 0 16.5694 0H15.3661H10.492H0V15.9603V19.56V35.7586H10.492H15.3661H16.5694C21.9399 35.7586 26.2931 31.3798 26.2931 25.988C26.2931 22.619 24.5933 19.6455 22.0081 17.8886Z" fill={fill} />
    </svg>
  </span>
);

Logo.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

Logo.defaultProps = {
  className: undefined,
  fill: undefined,
};

export default Logo;
